<!--
 * @Description: 保存
 * @Autor: WangYuan
 * @Date: 2021-09-27 16:53:55
 * @LastEditors: WangYuan
 * @LastEditTime: 2021-11-15 16:24:51
-->
<template>
  <el-dialog :visible.sync="show" width="800px" top="300px"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             :title="aid?'编辑应用':'新建应用'">
    <!--    class="flex-column col-center"-->
    <div>
      <!-- form -->
      <el-form :model="form" ref="form" label-width="150px" label-position="right">

        <el-form-item label="应用名称" prop="name" class="star">
          <el-input type="text" v-model="form.name" placeholder="请输入应用名称" clearable/>
        </el-form-item>

        <!--        `is_default` int DEFAULT '0' COMMENT '是否默认 0 否 1 是',-->
        <el-form-item label="是否系统默认" prop="isDefault" class="star">
          <el-select v-model="form.isDefault" placeholder="请选择是否系统默认">
            <el-option
                label="否"
                :value="0"
            >
            </el-option>
            <el-option
                label="是"
                :value="1"
            >
            </el-option>
          </el-select>
        </el-form-item>


        <el-form-item label="选择应用场景" prop="industrys" class="star">
          <el-checkbox-group v-model="form.industrys">
            <el-checkbox :label="item.sceneId" border v-for="(item,index) in sceneList" :key="index"
                         style="margin-bottom:10px;"
            >
              {{ item.name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>


        <el-form-item label="登录方式" prop="loginModes" v-if="authType!='silent'&&loginMode.length>0" class="star">
          <el-checkbox-group v-model="form.loginModes">

            <el-checkbox :label="item" border v-for="(item,index) in loginMode" :key="index"
                         style="margin-bottom:10px;"
            >
              {{ item == 'uname_pword' ? '账户密码登录' : item == 'phone+' ? '手机号登录' : '微信一键登录' }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>


        <el-form-item label="微信小程序appId" prop="wxMiniappId" class="form-item-none">
          <el-input type="text" v-model="form.wxMiniappId" clearable
                    maxlength="50"
                    show-word-limit></el-input>
        </el-form-item>

        <el-form-item label="微信小程序appSecret" prop="wxMiniappSecret" class="form-item-none">
          <el-input type="text" v-model="form.wxMiniappSecret" clearable
                    maxlength="50"
                    show-word-limit></el-input>
        </el-form-item>

        <el-form-item label="描述" prop="remark"  class="form-item-none star">
          <el-input type="textarea" v-model="form.remark"
                     maxlength="100"
                    show-word-limit></el-input>
        </el-form-item>


      </el-form>
    </div>

    <!-- submit -->
    <div slot="footer" class="dialog-footer flex-center">
      <el-button type="primary" round style="width: 140px" @click="submit"
      >保存
      </el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import {addProject, editProject} from "@/api/project";
import {mapGetters, mapMutations} from "vuex";
import {rojectModel} from "@/config/project";
import {mallIndustryList, mallTypeList} from "@/config/mall";
import {getSceneList} from "../../api/form";
import {shopUpdate, shopSave} from "../../api/easyApp";


export default {
  name: "CreateDialog",
  props: {
    authType: {
      type: String,
      default: '',
    },
    loginMode: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      mallIndustryList,
      show: false,
      form: {
        type: "mall",
        industrys: [],
        name: '',
        isDefault: '',
        remark: '',
        wxMiniappId: '',
        wxMiniappSecret: '',
        loginModes: []
      },
      sceneList: [],//场景列表
      aid: '',
      projectConfig: {},
    };
  },

  computed: {
    ...mapGetters(["userInfo"]),
  },

  methods: {
    ...mapMutations(["setProject"]),

    async open(project) {


      this.form = {
        type: "mall",
        industrys: [],
        name: '',
        isDefault: '',
        remark: '',
        wxMiniappId: '',
        wxMiniappSecret: '',
        loginModes: []
      }


      this.show = true;
      this.projectConfig = project;
      //获取到场景列表 getSceneList
      //获取租户列表
      let data = {
        descs: 'create_time',
        size: 1000,
        current: 1
      }
      let res = await getSceneList(data);

      /* loginModes:[],
           loginMode:''*/

      this.sceneList = res.data.records;
      console.log("====租户列表===:", res.data.records);


      if (project && project.aid) {
        console.log("==project==:", project);
        this.aid = project.aid;

        this.form.wxMiniappId = project.wxMiniappId;
        this.form.wxMiniappSecret = project.wxMiniappSecret;

        console.log("====project.aid===:", project.aid);

        if (project.industry) {
          this.form.industrys = project.industry.split(',');
        }

        if (project.loginMode) {
          this.form.loginModes = project.loginMode.split(',');
        } else {
          this.form.loginModes = [];
        }

        this.form.name = project.name;
        if (project.isDefault==0||project.isDefault==1) {
          this.form.isDefault = project.isDefault;
        }

        if (project.remark) {
          this.form.remark = project.remark;
        }

        console.log("===this.form===:", this.form);

      } else {
        this.aid = '';
        this.form.loginModes = [];

      }


    },

    submit() {
      /*  this.$refs["form"].validate((valid) => {
          if (valid) {*/

      if (!this.form.name) {

        this.$message({
          message: '请输入应用名称',
          type: 'warning'
        });
        return;
      } else if (this.form.isDefault!=0&&this.form.isDefault!=1) {


        this.$message({
          message: '请选择是否默认',
          type: 'warning'
        });
        return;
      } else if (this.form.industrys.length == 0) {
        this.$message({
          message: '请选择业务场景',
          type: 'warning'
        });
        return;
      }
      else if (!this.form.remark) {
        this.$message({
          message: '请输入描述信息',
          type: 'warning'
        });
        return;
      }


      if (this.authType != 'silent' && this.loginMode.length > 0) {

        if (this.form.loginModes.length == 0) {


          this.$message({
            message: '请选择登录方式',
            type: 'warning'
          });


          return;
        } else {
          this.create();
        }
      } else {
        this.create();
      }


      /*     }
         });*/


    },

    /**
     * 创建商城
     */
    async create() {

      if (this.aid) {

        let project = {
          ...this.form,
          //...{userId: this.userInfo.userId, logo: map.get(this.form.type)},
        };

        project['loginMode'] = this.$cloneDeep(this.form.loginModes).toString();


        project['industry'] = this.$cloneDeep(this.form.industrys).toString();
        project['aid'] = this.aid;

        /**/
        project.configEntity = this.projectConfig.configEntity;
        project.pagesEntityList = this.projectConfig.pagesEntityList;
        const bottomNavigation = JSON.parse(project.configEntity.bottomNavigation);
        bottomNavigation.list.forEach(ele => {
          if (ele.id == '00001') {
            ele.id = this.projectConfig.pagesEntityList.find(item => item.home == 1)?.pagesId;
            //  ele.jump.id = ele.id;
          } else if (ele.id == '00005') {
            ele.id = this.projectConfig.pagesEntityList.find(item => item.home == 2)?.pagesId;
            //  ele.jump.id = ele.id;
          }
        });
        project.configEntity.bottomNavigation = JSON.stringify(bottomNavigation);
        this.setProject(project);

        let {code, data} = await shopUpdate(project);

        if (code == "0") {

          //this.show=false;
          //this.$router.push({name: "mall"});
          this.show = false;
          this.$emit('success')
        }


      } else {


        //let map = new Map();
        // mallTypeList.map((item) => map.set(item.type, item.logo));

        let project = {
          ...this.$cloneDeep(rojectModel),
          ...this.form,
          //...{userId: this.userInfo.userId, logo: map.get(this.form.type)},
        };

        project['loginMode'] = this.$cloneDeep(this.form.loginModes).toString();


        if (this.form.industrys.length == 0) {
          this.$notify({
            title: "温馨提示",
            message: '请选择业务场景',
            type: "error",
          });
          return
        }

        project['industry'] = this.$cloneDeep(this.form.industrys).toString();

        console.log("===project===:", project);

        let {code, data} = await addProject(project);

        if (code == "0") {

          /* project.pagesEntityList = data.pagesEntityList;
           project.configEntity = data.configEntity;
           const bottomNavigation = JSON.parse(project.configEntity.bottomNavigation);
           bottomNavigation.list.forEach(ele => {
             if (ele.id == '00001') {
               ele.id = project.pagesEntityList.find(item => item.home == 1)?.pagesId;
               ele.jump.id = ele.id;
             } else if (ele.id == '00005') {
               ele.id = project.pagesEntityList.find(item => item.home == 2)?.pagesId;
               ele.jump.id = ele.id;
             }
           });
           project.configEntity.bottomNavigation = JSON.stringify(bottomNavigation);

           project.aid = data.aid;
           this.setProject(project);
           this.$router.push({name: "mall"});*/

          this.show = false;
          this.$emit('success')

        }


      }


    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../scss/variable";

input {
  border: 0;
  margin-bottom: 5px;
}

::v-deep .el-form-item {
  padding-bottom: 5px;
  // border-bottom: solid 1px #e3e3e3;

  .el-form-item__label {
    //text-align: left;
  }

  .el-form-item__error {
    top: 47px;
    left: -80px;
  }
}

.star ::v-deep .el-form-item__label:before {
  content: '*';
  color: #F56C6C;
  font-size: 14px;
  margin-right: 5px;
}

::v-deep .el-button--primary {
  background: $color-theme !important;
}

::v-deep .el-select {
  width: 100%;

  input {
    //padding-left: 0;
    // border: 0;
  }

}

::v-deep .el-input__inner {
  // border: 0px;
  //margin: 0px;
  //padding: 0px;
}

.form-item-none {
  border-color: transparent;
}
</style>